import { mapGetters } from 'vuex'
import Dinero from 'dinero.js'

export const CurrencyMixin = {
  computed: {
    ...mapGetters({
      CM_DEFAULT_CURRENCY: 'currency/currency',
      CM_CURRENCIES: 'currency/currencies'
    })
  },
  mounted () {
    console.log('CURRENCY MIXIN LOADED')
  },
  methods: {
    /*
      Tüm methodlar obje olarak dönecek gerekli şekilde kullanılacak.
      İsimlendirmelerde component methodlarıyla çakışmaları önlemek için adının baş harfleri kullanılacak.
      Ezmek istenilen method yine component içinde ön kısaltma ile birlikte yazılarak ezilecek.

      Methodlar
      cmSalePrice: Satış ücreti. Komisyon ve para birimi özellikleri dahil tutar.
      cmPercentage: Yüzde hesaplaması yapılarak gerekli yüzdelik gönderilir.
      CM_FLATTEN: Float veya integer olarak verilen değerleri hesaplamalarda karışıklıkları önlemek için ortak bir formata çevirir.
     */

    CM_FLATTEN (amount) {
      return Math.round(amount * Math.pow(10, 2))
    },

    CM_CONVERT (amount, from, to) {
      if (from !== null && to !== null) {
        return window.fx.convert(amount, {
          from: from, to: to
        }).toFixed(2)
      }
      return 0.00
    },

    CM_FORMAT (amount, currency) {
        if (typeof amount === 'string') {
          amount = amount.replace(/[^\d.-]/g, '')
        }

        if (typeof amount === 'object') {
          return Dinero({ amount: amount.getAmount(), currency: currency }).toFormat('0.00')
        } else if (this.isFloat(amount)) {
          return Dinero({ amount: this.CM_FLATTEN(amount), currency: currency }).toFormat('0.00')
        } else if (typeof amount === 'string') {
          return Dinero({ amount: this.CM_FLATTEN(parseFloat(amount).toFixed(2)), currency: currency }).toFormat('0.00')
        } else {
          return Dinero({ amount: amount, currency: currency }).toFormat('0.00')
        }
    },
    isInt (n) { return Number(n) === n && n % 1 === 0 },
    isFloat (n) { return Number(n) === n && n % 1 !== 0 }
  }
}
